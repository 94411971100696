import React from "react";

const Activity = ({ url, title, description, picture }) => {
  return (
    <article>
      <a href={url} aria-label={`Lien pour se rendre à ${title}`} role="link">
        <img src={picture} alt={`Photo de ${title}`} />
        <h2>{title}</h2>
      </a>
      <a
        href={url}
        aria-label={`Lien pour se rendre à ${title}`}
        role="presentation"
      >
        <p>{description}</p>
      </a>
    </article>
  );
};

export default Activity;
