import React from "react";

import styles from "../Home/home.styles.scss";

const Paiements = () => {
  return (
    <main className={`${styles.home} ${styles.other}`}>
      <div role="figure"></div>
      <article className={`${styles.welcome} ${styles.other}`}>
        <div className={styles.textBox}>
          <h2>Paiements et Remboursements</h2>
          <p>
            <span>
              <b>Paiement - Stripe : </b> <br /> <br />
              Nous acceptons les paiements par carte bancaire via la plateforme
              sécurisée Stripe. Avec Stripe, vous pouvez utiliser les
              principales cartes de crédit telles que Visa, Mastercard, American
              Express, ainsi que d'autres méthodes de paiement locales.
            </span>

            <br />
            <span>
              <b>Remboursement - Détails : </b> <br /> <br />
              Sur présentation d'un justificatif approprié, une partie du
              montant ou la totalité du prix de la location pourra être
              remboursée. Les conditions spécifiques de ce remboursement peuvent
              inclure :
              <br />
              <br />
              Cas de force majeure :<br /> En cas de circonstances
              exceptionnelles indépendantes de la volonté du locataire, telles
              que des catastrophes naturelles, des problèmes de santé graves
              documentés ou des événements imprévus majeurs, un remboursement
              total ou partiel pourra être envisagé.
              <br />
              <br />
              Non-conformité du bien loué :<br /> Si le logement ou l'équipement
              loué ne correspond pas à la description ou présente des défauts
              majeurs rendant son utilisation impossible ou dangereuse, un
              remboursement partiel ou total pourra être effectué après
              vérification.
              <br />
              <br />
              Documents justificatifs :<br /> Le locataire devra fournir les
              documents nécessaires pour justifier la demande de remboursement,
              tels que des certificats médicaux, des attestations d'assurance,
              des preuves de circonstances imprévues, etc.
              <br />
              <br />
              La demande de remboursement devra être faite par écrit,
              accompagnée des justificatifs appropriés, et sera examinée au cas
              par cas pour déterminer l'éligibilité et le montant du
              remboursement.
            </span>
          </p>
        </div>
      </article>
    </main>
  );
};

export default Paiements;
