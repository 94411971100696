import React from "react";
import styles from "./activities.styles.scss";
import Activity from "./Activity";

import aquarium from "../../../../htdocs/images/assets/aquarium_de_la_rochelle.jpg";
import karting from "../../../../htdocs/images/assets/kartingdesaintes.jpg";
import castle from "../../../../htdocs/images/assets/le-chateau-des-enigmes.jpg";
import phare from "../../../../htdocs/images/assets/phare_de_la_courbe.jpg";
import portvitrezay from "../../../../htdocs/images/assets/portvitrezay.jpg";
import antilles from "../../../../htdocs/images/assets/antilles_jonzac.jpg";
import velo from "../../../../htdocs/images/assets/velorail_saintonge.jpg";
import parc from "../../../../htdocs/images/assets/parc_aventure.jpg";
import zoo from "../../../../htdocs/images/assets/zoo_palmyre.jpg";

const Activities = () => {
  return (
    <section className={styles.activities_container}>
      <h2>
        <span>Activités</span> et lieux à découvrir en famille
        <br />
        ou entre amis à proximité !
      </h2>

      <p>
        <span>
          Découvrez des activités passionnantes à proximité et des lieux parfaits à visiter en famille ou entre amis. Que vous soyez en quête d'aventure, de détente ou de découvertes culturelles, de
          nombreuses possibilités s'offre à vous !
        </span>
      </p>

      <div className={styles.activities}>
        <Activity
          url={"https://www.aquarium-larochelle.com/"}
          title={"Aquarium de la Rochelle"}
          description={"Très bel endroit, reposant et féérique. Une impressionnante diversité d’animaux marins, les panneaux explicatifs sont très instructifs. A recommander avec ou sans enfant !"}
          picture={aquarium}
        />
        <Activity
          url={"https://kartingdesaintes.com/"}
          title={"Karting de Saintes"}
          description={
            "Location de karting pour adultes et enfants dès 7 ans (1m25). Réservation fortement conseillée par tél 06 15 35 73 54. Horaires mis à jour chaque jour sur google et facebook, en cas de mauvais temps pensez à vérifier "
          }
          picture={karting}
        />
        <Activity
          url={"https://www.chateau-enigmes.com/fr/charente-maritime"}
          title={"Le chateau des énigmes"}
          description={
            "Situé à Pons en Charente-Maritime, Le Château des Énigmes est un parc de loisirs qui permet de visiter, tout en s’amusant, un magnifique château de la Renaissance inscrit aux Monuments Historiques."
          }
          picture={castle}
        />
        <Activity
          url={"https://www.pharedelacoubre.fr/"}
          title={"Phare de La Coubre"}
          description={
            "Face à l’océan, d’Oléron à Royan, du haut de ses 64 mètres, il est le plus haut phare charentais et offre ainsi un panorama sur la Côte Sauvage de La Tremblade et ses magnifiques plages, la forêt de La Coubre, l’estuaire de la Gironde."
          }
          picture={phare}
        />
        <Activity
          url={"https://www.parc-aventure-fontdouce.com/fr/"}
          title={"Parc Aventure de Fondouce"}
          description={
            "Le parc accueille les sorties de groupes tous les jours d’avril à novembre. Nous adaptons cette journée à vos besoins et vos horaires. Contactez-nous pour organiser votre venue !"
          }
          picture={parc}
        />
        <Activity
          url={"https://www.portvitrezay.com/"}
          title={"Vitrezay échappées nature"}
          description={
            "Vitrezay fait partie du réseau des Échappées Nature, qui rassemble 50 000 hectares d'espaces naturels fragiles et exceptionnels de par leur flore et leur faune mais aussi leurs paysages."
          }
          picture={portvitrezay}
        />
        <Activity
          url={"hhttps://veloraildefrance.com/17-velorail-de-saintonge-minisite/"}
          title={"Vélorail de Saintonge"}
          description={
            "Vivez ou revivez une aventure insolite de 2h00 maximum en pédalant sur une ancienne voie ferrée. Vous pourrez allier plein air, « un peu » de sport, beaucoup de sensations et un zeste d’adrénaline. "
          }
          picture={velo}
        />
        <Activity
          url={"https://www.zoo-palmyre.fr/"}
          title={"Le Zoo de La Palmyre"}
          description={"Niché au coeur d'une forêt de pins de 18 hectares, le Zoo de La Palmyre est aujourd'hui l'un des sites touristiques les plus fréquentés de Charente-Maritime. "}
          picture={zoo}
        />
        <Activity
          url={"https://www.lesantillesdejonzac.com/"}
          title={"Les Antilles de Jonzac"}
          description={"Dans un décor tropical, pour les plus sportifs, les Antilles de Jonzac proposent un véritable bassin de natation !"}
          picture={antilles}
        />
      </div>
    </section>
  );
};

export default Activities;
