import React from "react";

import styles from "../../UserBookings/userBookings.styles.scss";

const Status = ({ status }) => {
  switch (status.name) {
    case "EN_ATTENTE_DE_CONFIRMATION":
      return <p className={`${styles.statusBooking} ${styles.inProgress}`}>En cours d'acceptation</p>;

    case "VALIDEE":
      return <p className={`${styles.statusBooking} ${styles.validate}`}>Validée</p>;

    case "PAYEE":
      return <p className={`${styles.statusBooking} ${styles.payed}`}>Payée</p>;

    case "ANNULEE":
      return <p className={`${styles.statusBooking} ${styles.canceled}`}>Annulée</p>;
  }
};

export default Status;
