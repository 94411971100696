import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { setOneLocation } from "../../Store/slices/locationsSlices";
import { hideHeader } from "../../utilities";

import styles from "./home.styles.scss";

import faces from "../../../../htdocs/images/assets/background_main.jpg";
import cottage_1 from "../../../../htdocs/images/assets/cottage_1.jpg";
import cottage_2 from "../../../../htdocs/images/assets/cottage_2.jpg";
import cottage_3 from "../../../../htdocs/images/assets/cottage_3.jpg";

import Locations from "../../components/Locations/Locations";
import Activities from "../../components/Activities/Activities";

const Home = () => {
  const { locations } = useSelector((state) => ({ ...state.location }));

  const itemWelcomeOne = useRef(null);
  const itemWelcomeTwo = useRef(null);
  const itemWelcomeThree = useRef(null);

  const locationHook = useLocation();
  const dispatch = useDispatch();

  const redirectToCottage = async (nbr) => {
    localStorage.getItem("location") ? localStorage.removeItem("location") : null;
    localStorage.getItem("dates") ? localStorage.removeItem("dates") : null;

    try {
      const formData = {
        cottage: "null",
        begin: "",
        end: "",
        capacity: "",
        hasSanitary: "",
        hasPool: "",
        animalAccepted: "",
        hasGarden: "",
      };

      const response = await axios.post("/location/search", formData);

      if (response.status === 200) {
        dispatch(setOneLocation(response.data[nbr]));
        hideHeader();

        setTimeout(() => {
          document.body.style.overflow = "initial";
        }, 4000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    /* ::::::::::::::::::::::::::::::::::::::::::::::::: */
    /* Réinitialisation des class */
    [itemWelcomeOne, itemWelcomeTwo, itemWelcomeThree].map((x) => x.current.classList.remove(styles.hideModify));

    if (locationHook.search === "?param=modify") {
      [itemWelcomeOne, itemWelcomeTwo, itemWelcomeThree].map((x) => x.current.classList.add(styles.hideModify));
    }

    if (locations.length !== 0) {
      [itemWelcomeOne, itemWelcomeTwo, itemWelcomeThree].map((x) => x.current.classList.add(styles.hide));
    } else {
      [itemWelcomeOne, itemWelcomeTwo, itemWelcomeThree].map((x) => x.current.classList.remove(styles.hide));
    }
  }, [locations, locationHook]);

  return (
    <main className={styles.home}>
      <div role="switch"></div>

      <section role="main">
        <div ref={itemWelcomeOne} role="figure"></div>

        <article ref={itemWelcomeTwo} className={styles.welcome}>
          <div className={styles.textBox}>
            <h2>
              Profitez de la <span>nature</span>
              <br />
              et détendez-vous
            </h2>
            <p>
              <span>
                Bienvenue sur notre plateforme de location de gîtes et cabanes, où la nature est au cœur de votre séjour ! Située à <strong>Rioux</strong> non loin de <strong>Saintes</strong> et{" "}
                <strong>Royan</strong>, notre offre vous plonge dans un monde où la tranquillité règne et où chaque souffle de vent vous transporte dans une aventure en harmonie avec la nature.
              </span>
              <span>
                A proximité de la <strong>Gironde</strong> en <strong>Charente-Maritime</strong>, que vous recherchiez une retraite romantique en amoureux, une escapade en famille ou une aventure
                entre amis, nous avons le lieu idéal pour répondre à vos besoins.
              </span>
              <Link to={"/qui-sommes-nous"}>
                <span>En apprendre plus</span>
              </Link>
            </p>
          </div>

          <div className={styles.imageBox}>
            <img src={faces} alt="Photo des propriétaires" />
          </div>
        </article>

        <section role="banner" ref={itemWelcomeThree} className={styles.banner}>
          <div>
            <h2>Découvrez nos hébergements</h2>
          </div>
          <article>
            <img onClick={() => redirectToCottage(0)} src={cottage_1} alt="Photo du premier hébergement" />
            <img onClick={() => redirectToCottage(1)} src={cottage_2} alt="Photo du second hébergement" />
            <img onClick={() => redirectToCottage(2)} src={cottage_3} alt="Photo du troisiéme hébergement" />
          </article>
          <article>
            <div>
              <p className={styles.textBox}>
                Notre site vous propose une sélection exclusive de gîtes et de cabanes nichés au cœur de paysages pittoresques, vous invitant à vous ressourcer loin de l'agitation urbaine. Découvrez
                nos hébergements uniques, tous soigneusement sélectionnés pour vous offrir une expérience authentique et immersive.
              </p>
            </div>
          </article>
        </section>

        {locations.length !== 0 ? <Locations locationsDatas={locations} /> : <></>}
      </section>

      {locations.length === 0 ? <Activities /> : <></>}
    </main>
  );
};

export default Home;
